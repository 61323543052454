
import LoadingSpinner from '@/components/loading_spinner'
import { as_json, number_to_currency } from '@/lib/helpers'

export default {
  components: { LoadingSpinner },

  data: -> {},

  computed: {
    loading: -> @$store.state.cart.loading,
    requesting_checkout: -> @$store.state.cart.requesting_checkout,
    comparisons: -> @$store.state.cart.comparisons,
    suppliers: -> @$store.getters['cart/suppliers'],
    items: -> @$store.state.cart.items,

    formatted_basket_subtotal: ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: @$store.getters['cart/subtotal_in_cents'] })

    basket_total: -> @$store.getters['cart/total'],
    order_id: -> @$store.state.orders.current?.id
  },

  created: ->
    @$store.dispatch('cart/fetch_comparisons')
    @$store.commit('layout/page_nav', {
      title: @$t('cart_compare_page_header'),
      back_target: '/basket'
    })

  methods: {
    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    checkout_with: (comparison) ->
      # filter out items without offers for chosen supplier
      comparison.items = comparison.items.filter((item) => item.offer_not_found != true)
      @$store.commit('checkout/setup', comparison)
      @$store.dispatch('checkout/fetch_client_config')
        .then (config) =>
          @$router.push('/checkout/dispatch')

    as_json: (reactive_shit) ->
      as_json(reactive_shit)

    available_items_count: (items) ->
      items.filter((item) -> !item.offer.out_of_stock_at).filter((item) -> !item.offer_not_found).length

    stock_short_items_count: (items) ->
      items.filter((item) -> !!item.offer.out_of_stock_at || !!item.offer_not_found).length

    items_available_class: (items) ->
      !!@stock_short_items_count(items) && "r---pill--red" || "r---pill--green"
  },

  watch: {
    order_id: (id) ->
      @$router.push("/orders/#{id}") if !!id
  }
}
